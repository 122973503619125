import React, { Component } from "react";
import { Link } from "react-router-dom";
import {Logo_icon} from "../../imagepath";

class Error404 extends Component {
  render() {
    return (

        <div className="login-page">
            <div className="login-body container">
                <div className="loginbox">
                    <div className="login-right-wrap">
                        <div className="error-box">
                            <h1>404</h1>
                            <h3 className="h2"><i className="fas fa-exclamation-triangle" /> Oops! Page not found!</h3>
                            <p className="h4 font-weight-normal">The page you requested was not found.</p>
                            <Link to="/" className="btn btn-primary">Back to Home</Link>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
  }
}

export default Error404;
