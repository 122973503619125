import React, {Component} from "react";
import {Link} from "react-router-dom";
import {
    Logo_icon,
    Provider_01,
    Provider_02,
    Provider_03,
    Provider_04,
    Provider_05,
    Provider_06,
    Provider_07,
    User
} from '../imagepath';

class Header extends Component {

    render() {
        if (this.props.isAuthenticated) {
            const exclusionArray = ["login", "register", "forgot-password", "lock-screen", "error-404", "error-500"];
            if (
                exclusionArray.indexOf(this.props.location.pathname.split("/")[1]) >= 0
            ) {
                return "";
            }
            const url = this.props.location.pathname.split("/")[1];
            // console.log('url', url);

            return (
                <>
                    {/* Header */}
                    <div className="header">
                        <div className="header-left">
                            <Link to="/" className="logo logo-small">
                                <img src={Logo_icon} alt="Logo" width={30} height={30}/>
                            </Link>
                        </div>
                        <a href="" id="toggle_btn">
                            <i className="fas fa-align-left"/>
                        </a>
                        <a className="mobile_btn" id="mobile_btn" href="">
                            <i className="fas fa-align-left"/>
                        </a>
                        <ul className="nav user-menu">
                            {/* Notifications */}
                            <li className="nav-item dropdown noti-dropdown">
                                <a href="#" className="dropdown-toggle nav-link" data-toggle="dropdown">
                                    <i className="far fa-bell"/> <span className="badge badge-pill"/>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right notifications">
                                    <div className="topnav-dropdown-header">
                                        <span className="notification-title">Notifications</span>
                                        <a href="" className="clear-noti"> Clear All </a>
                                    </div>
                                    <div className="noti-content">
                                        <ul className="notification-list">
                                            <li className="notification-message">
                                                <Link to="/admin-notification">
                                                    <div className="media">
                        <span className="avatar avatar-sm">
                          <img className="avatar-img rounded-circle" alt="" src={Provider_01}/>
                        </span>
                                                        <div className="media-body">
                                                            <p className="noti-details">
                                                                <span className="noti-title">Thomas Herzberg have been subscribed</span>
                                                            </p>
                                                            <p className="noti-time">
                                                                <span
                                                                    className="notification-time">15 Sep 2020 10:20 PM</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li className="notification-message">
                                                <Link to="/admin-notification">
                                                    <div className="media">
                        <span className="avatar avatar-sm">
                          <img className="avatar-img rounded-circle" alt="" src={Provider_02}/>
                        </span>
                                                        <div className="media-body">
                                                            <p className="noti-details">
                                                                <span className="noti-title">Matthew Garcia have been subscribed</span>
                                                            </p>
                                                            <p className="noti-time">
                                                                <span
                                                                    className="notification-time">13 Sep 2020 03:56 AM</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li className="notification-message">
                                                <Link to="/admin-notification">
                                                    <div className="media">
                        <span className="avatar avatar-sm">
                          <img className="avatar-img rounded-circle" alt="" src={Provider_03}/>
                        </span>
                                                        <div className="media-body">
                                                            <p className="noti-details">
                                                                <span className="noti-title">Yolanda Potter have been subscribed</span>
                                                            </p>
                                                            <p className="noti-time">
                                                                <span
                                                                    className="notification-time">12 Sep 2020 09:25 PM</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li className="notification-message">
                                                <Link to="/admin-notification">
                                                    <div className="media">
                        <span className="avatar avatar-sm">
                          <img className="avatar-img rounded-circle" alt="User Image" src={Provider_04}/>
                        </span>
                                                        <div className="media-body">
                                                            <p className="noti-details">
                                                                <span className="noti-title">Ricardo Flemings have been subscribed</span>
                                                            </p>
                                                            <p className="noti-time">
                                                                <span
                                                                    className="notification-time">11 Sep 2020 06:36 PM</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li className="notification-message">
                                                <Link to="/admin-notification">
                                                    <div className="media">
                        <span className="avatar avatar-sm">
                          <img className="avatar-img rounded-circle" alt="User Image" src={Provider_05}/>
                        </span>
                                                        <div className="media-body">
                                                            <p className="noti-details">
                                                                <span className="noti-title">Maritza Wasson have been subscribed</span>
                                                            </p>
                                                            <p className="noti-time">
                                                                <span
                                                                    className="notification-time">10 Sep 2020 08:42 AM</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li className="notification-message">
                                                <Link to="/admin-notification">
                                                    <div className="media">
                        <span className="avatar avatar-sm">
                          <img className="avatar-img rounded-circle" alt="User Image" src={Provider_06}/>
                        </span>
                                                        <div className="media-body">
                                                            <p className="noti-details">
                                                                <span className="noti-title">Marya Ruiz have been subscribed</span>
                                                            </p>
                                                            <p className="noti-time">
                                                                <span
                                                                    className="notification-time">9 Sep 2020 11:01 AM</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>
                                            <li className="notification-message">
                                                <Link to="/admin-notification">
                                                    <div className="media">
                        <span className="avatar avatar-sm">
                          <img className="avatar-img rounded-circle" alt="User Image" src={Provider_07}/>
                        </span>
                                                        <div className="media-body">
                                                            <p className="noti-details">
                                                                <span className="noti-title">Richard Hughes have been subscribed</span>
                                                            </p>
                                                            <p className="noti-time">
                                                                <span
                                                                    className="notification-time">8 Sep 2020 06:23 AM</span>
                                                            </p>
                                                        </div>
                                                    </div>
                                                </Link>
                                            </li>
                                        </ul>
                                    </div>
                                    <div className="topnav-dropdown-footer">
                                        <Link to="/admin-notification">View all Notifications</Link>
                                    </div>
                                </div>
                            </li>
                            {/* /Notifications */}
                            {/* User Menu */}
                            <li className="nav-item dropdown">
                                <a href="" className="dropdown-toggle user-link  nav-link" data-toggle="dropdown">
              <span className="user-img">
                <img className="rounded-circle" src={User} width={40} alt="Admin"/>
              </span>
                                </a>
                                <div className="dropdown-menu dropdown-menu-right">
                                    <Link className="dropdown-item" to="/admin-profile">Profile</Link>
                                    <Link className="dropdown-item" to="/login">Logout</Link>
                                </div>
                            </li>
                            {/* /User Menu */}
                        </ul>
                    </div>
                    {/* /Header */}
                </>
            );
        } else {
            return null
        }
    }
}

export default Header;
